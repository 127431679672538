<template>
	<div ref="qrPanel" class="qr-panel">
		<div class="row">
			<div class="columns">
				<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`" class="phone-button">
					<h3>{{ $t('bookByPhone') }}</h3>
					<div>
						<font-awesome-icon class="icon-margin" :icon="`fal fa-phone`" size="1x" />
						<span>{{ defaults[locale].website.phone }}</span>
					</div>
				</a>
				<div class="qr-container booking bookForm">
					<div class="qr-section">
						<h4>{{ bookingForm.bookARoom }}</h4>
						<div class="border" />
					</div>
					<div class="qr-section">
						<div>
							<label>{{ bookingForm.checkin }}</label>
							<client-only>
								<date-picker
									v-model:value="state.arrival"
									:clearable="false"
									:disabled-date="disableArrivalDates"
									value-type="format"
									format="DD-MM-YYYY"
									:placeholder="bookingForm.arrival"
									class=""
									:lang="datepickerLang"
									@pick="pickArrival"
								>
									<template #icon-calendar> &nbsp; </template>
								</date-picker>
							</client-only>
						</div>
						<div>
							<label>{{ bookingForm.checkout }}</label>
							<client-only>
								<date-picker
									v-model:value="state.departure"
									:open="state.openDeparture"
									:disabled-date="disableDepartureDates"
									value-type="format"
									format="DD-MM-YYYY"
									:placeholder="bookingForm.departure"
									:default-value="state.pickedArrivalDate ?? new Date()"
									class=""
									:lang="datepickerLang"
									@open="state.openDeparture = true"
									@close="state.openDeparture = false"
								>
									<template #icon-calendar> &nbsp; </template>
								</date-picker>
							</client-only>
						</div>
					</div>
					<div class="qr-section mobile-section">
						<book-button
							class="qr-section mobile-section button"
							:arrival="state.arrival"
							:departure="state.departure"
						>
							{{ bookingForm.availability }}
						</book-button>
					</div>
					<div class="qr-section mobile-section">
						<div class="border" />
						<h4>{{ bookingForm.benefitsHeader }}</h4>
						<ul class="qr-benefits">
							<li v-for="(bookNowAndSaveMoney, index) in bookingForm.bookNowAndSaveMoney" :key="index">
								<font-awesome-icon :icon="`far fa-circle-check`" size="1x" />
								<div v-parse-links v-html="bookNowAndSaveMoney.content" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

defineProps({
	bookingForm: { type: Object, default: () => {} },
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style lang="scss">
.mx-calendar-content .cell.active {
	background-color: $primary-color;
}
</style>

<style lang="scss" scoped>
.row {
	position: absolute;
	inset: 0;
	z-index: 5;
}

.qr-panel {
	opacity: 1;

	&.mounted {
		margin-left: 0;

		.qr-container {
			margin-left: 0;
			opacity: 1;
		}
	}

	.columns:first-child {
		border-radius: 0 3px 0 0;
		position: absolute;
		left: 0;
		top: 55%;
		transform: translateY(-50%);
		width: 100%;
	}
}

.qr-container {
	display: flex;
	flex-flow: wrap column;
	background: rgb(227 227 239 / 87%);
	padding: 40px;
	max-width: 440px;
	transition:
		margin 0.7s ease-out,
		opacity 0.9s ease-out;
	margin-left: -120vw;
	opacity: 0;

	.border {
		width: 100%;
		height: 1px;
		background: rgb(0 0 0 / 30%);
		margin: 20px 0;
	}
}

.qr-benefits {
	padding: 10px 0 0;
}

.qr-section {
	width: 100%;
	display: flex;
	flex-flow: wrap row;
	justify-content: space-between;

	label {
		font-size: 18px;
		margin: 0 0 10px;
	}

	&:last-child {
		flex-flow: wrap column;
	}

	> div {
		display: flex;
		flex-flow: wrap column;
		width: 48%;
	}

	.mx-datepicker {
		width: 100%;
	}

	:deep(input:not(.button)),
	:deep(select) {
		border: none;
		border-radius: 0;
		background: url('~/assets/images/calendar-bookform-icon.png') right 10px center no-repeat #fff;
		background-size: 18px;
		height: 54px;
		padding: 10px 20px;
		box-shadow: none;
		color: $primary-text-color;
		font-size: 18px;
		font-family: $heading-font-family;
		font-weight: 300;
		position: relative;
		z-index: 1;
		cursor: pointer;
	}

	.button {
		text-align: center;
		height: 54px;
		line-height: 54px;
		padding: 0;
		margin: 20px 0 0;
	}

	ul {
		list-style-type: none;

		li {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: flex-start;
			margin: 14px 0 0;

			svg {
				margin: 0 10px 0 0;
				font-size: 20px;
				color: #9897bd;
			}
		}
	}
}

.phone-button {
	position: absolute;
	right: 30px;
	bottom: 0;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	background: #9897bd;
	color: #fff;
	padding: 15px 20px;
	z-index: 6;
	text-decoration: none;

	span {
		font-size: 14px;
	}

	h3 {
		color: #fff;
		font-size: 20px;
		margin: 0 0 10px;
	}
}

@media (max-width: 960px) {
	.qr-panel {
		margin-top: -125px;

		.row,
		.columns:first-child,
		.phone-button {
			position: relative;
			inset: auto;
		}

		.columns:first-child {
			transform: none;
			padding: 0;
		}

		.qr-container {
			display: flex;
			flex-flow: column-reverse wrap;
			max-width: 100%;
			width: 100%;
			background: rgb(227 227 239);
		}

		.phone-button {
			margin: 0 auto 20px;
			max-width: 290px;
		}

		.qr-section,
		.border {
			display: none;
		}

		.mobile-section {
			display: block;
		}
	}
}
</style>
