<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #extra-content>
				<booking-form :booking-form="defaults[locale].bookingForm" />
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #content>
				<div class="columns column6">
					<h4 v-if="page.subtitle" class="subtitle">{{ page.subtitle }}</h4>
					<h1>{{ page.title }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</template>
			<template #right-content>
				<div class="columns column6">
					<picture>
						<source :srcset="defaults[locale].website.indexIntroImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].website.indexIntroImage" />
						<img
							:src="defaults[locale].website.indexIntroImage"
							:alt="defaults[locale].website.indexIntroImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
			</template>
		</main-content>

		<section v-if="defaults[locale].website.benefit.length" class="main-content usp">
			<div class="row align-center">
				<div v-for="(benefit, index) in defaults[locale].website.benefit" :key="index" class="columns column4">
					<font-awesome-icon :icon="benefit.icon" size="1x" />
					<div v-parse-links v-html="benefit.content" />
				</div>
			</div>
		</section>

		<contentblocks :contentblocks="page.contentblocks">
			<template #extra-content>
				<div class="row row-header">
					<div v-parse-links class="columns column12 align-center" v-html="page.contentBlockContent" />
				</div>
			</template>
		</contentblocks>

		<section class="main-content room-section">
			<div class="row row-header">
				<div
					v-parse-links
					class="columns column12 align-center"
					v-html="defaults[locale].website.promotionsContent"
				/>
			</div>
			<div class="row rooms packages-section align-center">
				<div v-for="promo in promoblocksData" :key="promo.ID" class="columns column4">
					<div class="promo-wrapper">
						<nuxt-link :to="localePath(`/${promo.link}`)">
							<div class="content">
								<h3>{{ promo.header }}</h3>
								<div v-parse-links v-html="promo.content" />
								<div class="button">{{ promo.button }}</div>
							</div>
							<div class="image-gradient" />
							<picture>
								<source :srcset="promo.imageWebp" type="image/webp" />
								<source :srcset="promo.image" />
								<img :src="promo.image" :alt="promo.imageAlt" loading="lazy" />
							</picture>
						</nuxt-link>
					</div>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<pre-footer v-if="page.footerSection && page.footerSection.length" :footer-section="page.footerSection[0]" />
		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletterForm.header"
			:content="defaults[locale].newsletterForm.content"
			:email="defaults[locale].newsletterForm.email"
			:button="defaults[locale].newsletterForm.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
#intro {
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		background: $background-color-light;
		width: 40%;
		z-index: 1;
	}

	.row {
		position: relative;
		z-index: 2;
	}
}

.room-section {
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		inset: 50% 0 0;
		background: $background-color-light;
		width: 100%;
		z-index: 1;
	}

	.row {
		position: relative;
		z-index: 2;
	}
}

h1 {
	margin: 25px 0;
}

.row-header {
	max-width: 1000px;
	margin: 0 auto 40px;
}

.usp {
	background: $background-color-light;

	svg {
		color: $background-color-dark;
		font-size: 44px;
		margin: 5px 0 20px;
	}
}

@media (max-width: 960px) {
	.usp .column4 {
		width: 100%;
		margin: 20px auto;
	}

	.packages-section .column4 {
		margin: 20px auto;
	}

	#intro {
		&::after {
			top: 50%;
		}
	}
}
</style>
